<template>
  <travio-center-container pageTitle="Add Deposit Options">
    <vx-card>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.name" v-validate="'required|max:50'" name="Name" />
          <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
        </div>
      </div>
      
      <div class="vx-row">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Message*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <!-- <vs-input class="w-full" v-model="form.message" name="Message"  v-validate="'max:100'" /> -->
          <vs-textarea 
              name="Message"
              counter="200"
              maxlength="200"
              rows="2" 
              v-model="form.message"
              v-validate="'required|max:200'"
              @focus="messageFieldHasFocus = true"
              @blur="messageFieldHasFocus = false"
              class="w-full"/>
              
          <span class="text-danger text-sm">{{ errors.first('Message') }}</span>
        </div>
        
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <span v-html="messageTagsTable" class="text-sm"></span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Amount*</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input class="w-full" type="number" min="0" name="Amount" v-model.number="form.amount" v-validate="'required|decimal|greaterThanZero'" />
          <span class="text-danger text-sm">{{ errors.first('Amount') }}</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-checkbox class="w-full mt-2" v-model="form.isPercentage">Percentage</vs-checkbox>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Minimum Amount*</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input class="w-full" type="number" min="0" name="Minimum Amount" v-model.number="form.minAmount" v-validate="'required|decimal'" />
          <span class="text-danger text-sm">{{ errors.first('Minimum Amount') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Currency*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select :value="selectedCurrency" name="Currency" @input="setSelectedCurrency" v-validate="'required'" :options="currencyOptions" />
          <span class="text-danger text-sm">{{ errors.first('Currency') }}</span>
        </div>
      </div>
      
     
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Installment</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <vs-switch class="" v-model="form.hasInstallments" />
        </div>
      </div>
      
      <div class="vx-row mb-6" >
        <div class="vx-col sm:w-1/5 w-full">
          <span>Minimum Days Before</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input class="w-full" type="number" min="0" name="Minimum Days Before" v-model.number="form.minDaysBefore" v-validate="'required|min_value:0'"/>
          <span class="text-danger text-sm">{{ errors.first('Minimum Days Before') }}</span>
        </div>
      </div>

      <div v-if="form.hasInstallments">

        <div class="vx-row mb-6" >
          <div class="vx-col sm:w-1/5 w-full">
            <span>Number of Installments</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <v-select v-model.number="form.installmentCount" name="Installments" :options="Array.from({length: 24}, (_, i) => i + 1)" v-validate="'required'" />
            <span class="text-danger text-sm">{{ errors.first('Installments') }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Installment Amount</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input class="w-full" type="number" min="0" name="Installment Amount" v-model.number="form.installmentAmount" v-validate="'required|min_value:0'"/>
            <span class="text-danger text-sm">{{ errors.first('Installment Amount') }}</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-checkbox class="w-full mt-2" v-model="form.installmentIsPercentage">Percentage</vs-checkbox>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Repeat</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <v-select v-model.number="form.installmentRepeat" name="Repeat" :options="Array.from({length: 31}, (_, i) => i + 1)" v-validate="'required'" />
            <span class="text-danger text-sm">{{ errors.first('Repeat') }}</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <v-select :value="selectedRepeatUnit" @input="setSelectedRepeatUnit" name="Repeat Unit" placeholder="Unit" :options="repeatUnitOptions" v-validate="'required'" />
            <span class="text-danger text-sm">{{ errors.first('Repeat Unit') }}</span>
          </div>
        </div>
      </div>
      
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Linked Option ID</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="form.linkedOptionId" name="Linked Option ID" :options="linkedOptions" :reduce="(x) => x.code"/>
          <span class="text-danger text-sm">{{ errors.first('Linked Option ID') }}</span>
        </div>
      </div>
      
      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button @click='onCancel' color="danger">Cancel</vs-button>
        <vs-button @click='onSave' class="ml-4">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'

export default {
  components: {
    vSelect
  },
  props: {
    applicationId: { required: true }
  },
  mixins: [TpNotificationMixin],
  data () {
    return {
      form: new Form({
        name: '',
        message: null,
        amount: 0,
        isPercentage: true,
        minAmount: 0,
        currency: null,
        minDaysBefore: 0,
        installmentCount: 1,
        installmentAmount: null,
        installmentRepeat: 1,
        installmentRepeatUnit: "M",
        installmentIsPercentage: true,
        hasInstallments: false,
        linkedOptionId: null,
      }),
      selectedCurrency: null,
      selectedRepeatUnit: { label: "Months", code: "M" },
      currencyOptions:  [],
      linkedOptions: [],
      repeatUnitOptions:  [
        { label: "Days", code: "D" },
        { label: "Months", code: "M" }
      ],
      messageFieldHasFocus: false,
      messageTagsTable: `
        <div class="strong">Available Message Tags:</div>
        <table style="width:100%">
          <colgroup>
            <col style="width:40%" />
            <col style="width:60%" />
          </colgroup>
          <tr>
            <td>{AMOUNT}</td>
            <td>The deposit amount</td>
          </tr>
          <tr>
            <td>{INSTALLMENTS}</td>
            <td>Number of installments</td>
          </tr>
          <tr>
            <td>{INSTALLMENTSAMOUNT}</td>
            <td>Installments amount</td>
          </tr>
          <tr>
            <td>{DUEDATE}</td>
            <td>Due date for final payment</td>
          </tr>
        </table>
      `
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  created () {
    this.$validator.extend(
      'greaterThanZero',{
      getMessage: field =>  field + ' needs to be greater than zero.',
      validate: (value) => {
        // value must be > zero
        if (value > 0 ) return true;
        return false;
      }
    });
  },
  async mounted () {
     //Check if user has acccess to the company
    if(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) {

      this.$vs.loading()
      try {
        const regionalSettingsPromise = this.$http.get(`/api/applications/${this.applicationId}/regionalSettings`)
        const linkedOptionsPromise = this.$http.get(`/api/depositoptions/apps/${this.applicationId}`)
        const [regionalSettingsResponse, linkedOptionsResponse] = await Promise.all([regionalSettingsPromise, linkedOptionsPromise])
        this.currencyOptions = regionalSettingsResponse.data.currencies.map(x => ({ code: x, label: x}));
        this.linkedOptions = linkedOptionsResponse.data.map(x => ({ code: x.id, label: x.name}));
        this.linkedOptions = [ {code: 0, label: 'Not linked to another deposit option'}, ...this.linkedOptions];
        this.form.linkedOptionId = 0
        this.setSelectedCurrency(this.currencyOptions[0])

      }
      catch (error) {
        this.$_notifyFailure(error)
      }

      this.$vs.loading.close();

    } else {
      this.$router.push('/error-404')
    }
  },
  methods: {
    setSelectedCurrency (value) {
      this.form.currency = value ? value.code : null
      this.selectedCurrency = value;
    },
    setSelectedRepeatUnit (value) {
      this.form.installmentRepeatUnit = value ? value.code : null
      this.selectedRepeatUnit = value;
    },
    onSave () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.post(`api/depositoptions/apps/${this.applicationId}`)
            .then(response => {
              this.$_notifySuccess('Deposit option successfully saved');
              this.backToList()
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    backToList () {
      this.$router.push({ name: 'application-depositoptions', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    onCancel () {
      this.backToList()
    }
  }
}
</script>

<style>
 
</style>
